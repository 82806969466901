import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import CTAButton from "../components/CTAButton"

const NotFoundPage = () => (
  <Layout noMenu={true}>
    <SEO />

    <div className="container  has-text-centered">

      <br />
      <br />
      <br />

      <h1 className="title is-1">
        Strony nie znaleziono!
      </h1>

      <CTAButton
        style={{ width: 'auto', padding: '2rem', marginTop: 50 }}
        href={'/'}
        label={'Wróć do strony głównej!'}
      />

    </div>

  </Layout>
)

export default NotFoundPage
